<template>
  <v-overlay :value="overlay">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
    <div class="text-subtitle-2" v-text="'帳號驗證開通中'"></div>
    <!-- dialog -->
    <MsgDialog :dialogProps="dialog">
      <template v-slot:custom-card-actions>
        <v-btn
          color="green darken-1"
          outlined
          @click="clickDialogBtn('home')"
          v-if="dialog.btns.home"
          >回首頁</v-btn
        >
        <v-btn
          color="green darken-1"
          outlined
          @click="clickDialogBtn('login')"
          v-if="dialog.btns.login"
          >返回登入</v-btn
        >
        <v-btn
          color="green darken-1"
          outlined
          @click="clickDialogBtn('close')"
          v-if="dialog.btns.close"
          >關閉</v-btn
        >
        <v-btn
          color="green darken-1"
          outlined
          @click="clickDialogBtn('abort')"
          v-if="dialog.btns.abort"
          >跳出</v-btn
        >
      </template>
    </MsgDialog>
    <!-- dialog for processing end -->
  </v-overlay>
</template>

<script>
import { apiMemberPostRegisterVerify } from '@api/member.js';
import MsgDialog from '@component/dialog/MsgDialog';
export default {
  components: { MsgDialog },
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
        };
      }
    }
  },
  data () {
    return {
      vd: '',
      overlay: false,
      dialog: {
        init: false,
        active: false,
        isClose: false, // 關閉訊息窗
        title: '',
        msg: '',
        process: false,
        persistent: true,
        btns: {
          close: false, // 關閉訊息窗
          login: false, // 返回會員燈入頁
          home: false, // 返回平台首頁
          abort: false // 跳出目前視窗
        }
      }
    };
  },
  methods: {
    /**
     * [clickDialogBtn]
     */
    clickDialogBtn (tg = '') {
      // console.log(`[Verify][clickDialogBtn] ... tg: ${tg}`);
      let _path = '';
      const _dialog = this.$options.data().dialog;
      switch (tg) {
        case 'home':
          _dialog.isClose = true;
          _path = '/home';
          break;
        case 'login':
          _dialog.isClose = true;
          _path = '/login';
          break;
        case 'close':
          _dialog.isClose = true;
          break;
        case 'abort':
          window.close();
          break;
      }
      this.$set(this, 'dialog', _dialog);
      // redirect
      console.log('path : ' + _path);
      if (_path !== '') {
        console.log('redirect to : ' + _path);
        this.$router
          .push({
            path: _path
          })
          .catch((error) => {
            // Vue-route3.1版本之後，重複點擊or來源目的連結相同會報 error: NavigationDuplicated，要排除不動作即可
            if (error.name !== 'NavigationDuplicated') {
              return error;
            }
          });
      }
    },
    /**
     * [registerVerify] 帳號開通驗證
     */
    async registerVerify () {
      console.log('[register/verify][registerVerify] ...');
      let _dialog = this.$options.data().dialog;
      if (!this.vd) {
        _dialog.msg = '帳號開通驗證入參不正確';
        this.$set(this, 'dialog', _dialog);
        return false;
      }

      const _errCode = {
        101: '帳號開通驗證失敗',
        102: '查無資料',
        105: '帳號開通驗證發生異常'
      };

      // dialog with progress
      _dialog.process = true;
      _dialog.title = '帳號開通驗證處理中，請稍後...';
      this.$set(this, 'dialog', _dialog);

      let fail = {};
      let result = null;
      // dialog
      _dialog = this.$options.data().dialog;
      try {
        // axios api
        result = await apiMemberPostRegisterVerify({ vd: this.vd });
        console.log(result);
        // result = null;
        // 失敗
        if (!result || !result.status) {
          fail = { action: 'error', status: 101, msg: _errCode[101], data: result };
          _dialog.title = _errCode[101];
          _dialog.msg = result.msg;
          _dialog.btns.home = true;
          this.$set(this, 'dialog', _dialog);
          return false;
        }
        if (!result.data) {
          fail = { action: 'error', status: 102, msg: _errCode[102], data: result };
          _dialog.title = _errCode[102];
          _dialog.msg = result.msg;
          _dialog.btns.home = true;
          this.$set(this, 'dialog', _dialog);
          return false;
        }
        // dialog with success
        _dialog.title = '帳號開通驗證成功';
        _dialog.msg = '您的帳號已完成開通，請回登入頁登入平台。';
        _dialog.btns.login = true;
        _dialog.btns.home = true;
        this.$set(this, 'dialog', _dialog);
      } catch (error) {
        fail = { action: 'error', status: 105, msg: _errCode[105], data: error };
        console.log('加入會員發生異常');
        console.log(fail);
        // dialog with error
        // _dialog.title = _errCode[105];
        _dialog.msg = error;
        _dialog.btns.home = true;
        this.$set(this, 'dialog', _dialog);
        return false;
      }
    }
  },
  // beforeCreate () {
  //   console.log('[RegisterVerify][beforeCreate] ... ');
  // },
  created () {
    // console.log('[RegisterVerify]][created] ... ');
    this.overlay = true;
    if (this.$route.query.vd != null) {
      this.vd = this.$route.query.vd;
      this.registerVerify();
    }
  }
  // beforeMount () {
  //   console.log('[RegisterVerify][beforeMount] ... ');
  // },
  // mounted () {
  //   console.log('[RegisterVerify][mounted] ... ');
  // },
  // beforeUpdate () {
  //   console.log('[RegisterVerify][beforeUpdate] ... ');
  // },
  // updated () {
  //   console.log('[RegisterVerify][updated] ... ');
  // }
};
</script>
<style scoped>
</style>
